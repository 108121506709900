<template>
  <div id="news">

    <div class="content"
         v-if="dataTable.length>0">
      <div class="item"
           @click="todetail(item.id)"
           v-for="(item,i) in dataTable"
           :key="i">
        <div class="item_left">
          <b-img :src="item.surface_img"
                 fluid
                 alt="placeholder"></b-img>
        </div>
        <div class="item_right">
          <div class="item_title">{{item.title}}</div>
          <div class="item_info">{{item.info}}</div>
          <div class="time">
            <b-icon-calendar></b-icon-calendar>{{item.created_at}}
          </div>
        </div>

      </div>
      <div class="item more"
           v-show="!is_all"
           @click="more">查看更多</div>
      <div class="item more"
           v-show="is_all">已加载全部</div>
    </div>
    <div class="kong_fa"
         v-else>
      <div class="kong">
        <img src="../../assets/img/index/kong2.png"
             alt="">
        <div class="kong_text">管理员还未发布新闻</div>
      </div>
    </div>
    <myhead :background="'dark'"></myhead>
    <foot></foot>
    <right></right>
  </div>
</template>

<script>
import myhead from '@/components/head.vue'
import foot from '@/components/foot.vue'
import right from '@/components/right.vue'
export default {
  name: "news",
  data () {
    return {
      is_all: false,
      dataTable: [
      ],
      dataPagination: {
        currentPage: 1, //当前页码
        perPage: 15, //每页显示
        lastPage: 1, //总页码
        total: 1, //总条
      },
    }
  },
  components: {
    foot,
    right,
    myhead
  },
  mounted () {
    this.list()
  },
  methods: {
    more () {
      this.dataPagination.currentPage++;
      this.list()
    },
    todetail (id) {
      this.$router.push({ name: "NewsDetail", query: { id: id } })
    },
    list () {
      let params = {
        with_paginate: 1,
        page: this.dataPagination.currentPage,
      }
      this.$axios.get(`${this.$Tools.Conts.domain}official/news`, { params }).then(res => {
        if (res.data.code == 0) {
          res.data.data.data.map((val) => {
            let dd = val.body.replace(/<[^>]+>/g, "");//截取html标签
            let dds = dd.replace(/&nbsp;/ig, "");//截取空格等特殊标签
            val["info"] = dds.substring(0, 200)
          })

          if (this.dataPagination.currentPage == 1) {
            this.dataTable = res.data.data.data
          } else {
            res.data.data.data.map((val) => {
              this.dataTable = this.dataTable.concat(val)
            })
          }
          if (res.data.data.data.length == 0) {
            this.$toast.warn({
              message: "暂无更多数据",
              timeOut: 2000
            })
            this.is_all = true
          }

          Object.assign(this.dataPagination, {
            lastPage: res.data.data.last_page, //总页码
            perPage: res.data.data.per_page, //每页显示
            total: res.data.data.total, //总条数
          });
        } else {
          this.$toast.warn({
            message: res.data.message,
            timeOut: 2000
          })
        }

      })
    }
  }
}
</script>

<style lang="less" scoped>
#news {
  background: rgb(247, 247, 247);
}
.content {
  padding: 5rem 0 2rem;
  .item {
    background: white;
    border-radius: 6px;
    padding: 1.5rem;
    width: 90%;
    margin: 0 auto 0.8rem;
    cursor: pointer;
    // display: flex;
    .item_left {
      border-radius: 6px;
      overflow: hidden;
      flex-shrink: 0;
      margin-right: 0.5rem;
      // position: relative;
      // padding-bottom: 56%;
      img {
        width: 100%;
        // height: 100%;
        // position: absolute;
        // left: 0;
        // top: 0;
      }
    }
    .item_right {
      .item_title {
        font-size: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.2rem;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .item_info {
        font-size: 0.7rem;
        color: #777777;
        margin-bottom: 0.4rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .time {
        font-size: 0.7rem;
        color: #777777;
        display: flex;
        align-items: center;
        .b-icon {
          margin-right: 0.3rem;
        }
      }
    }
    &.more {
      padding: 0.5rem 0;
      color: rgb(45, 140, 240);
      font-size: 0.8rem;
      text-align: center;
      cursor: pointer;
      display: block;
    }
  }
}
.kong_fa {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8rem 0 5rem;
  img {
    width: 80%;
  }
  .kong_text {
    padding-top: 1rem;
    font-size: 1.1rem;
    color: #777777;
  }
}
@media screen and (min-width: 576px) {
  #news {
    background: rgb(247, 247, 247);
  }
  .content {
    padding: 5rem 0 2rem;
    .item {
      background: white;
      border-radius: 6px;
      padding: 1.5rem;
      margin: 0 auto 0.8rem;
      cursor: pointer;
      width: 70%;
      display: flex;
      .item_left {
        width: 10rem;
        // height: 5rem;
        margin-right: 1.5rem;
        margin-bottom: 0;
        border-radius: 6px;
        overflow: hidden;
        flex-shrink: 0;
        padding-bottom: 0;
        img {
          position: inherit;
          width: 100%;
          // height: 100%;
        }
      }
      .item_right {
        .item_title {
          font-size: 1.2rem;
          margin-top: 0rem;
          margin-bottom: 0.2rem;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .item_info {
          font-size: 0.8rem;
          color: #777777;
          margin-bottom: 0.4rem;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .time {
          font-size: 0.8rem;
          color: #777777;
          display: flex;
          align-items: center;
          .b-icon {
            margin-right: 0.3rem;
          }
        }
      }
      &.more {
        padding: 0.5rem 0;
        color: rgb(45, 140, 240);
        font-size: 0.8rem;
        text-align: center;
        cursor: pointer;
        display: block;
      }
    }
  }
  .kong_fa {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8rem 0 5rem;
    img {
      width: 50%;
    }
    .kong_text {
      padding-top: 1rem;
      font-size: 1.1rem;
      color: #777777;
    }
  }
}
</style>